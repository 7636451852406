import { useState } from 'react';

interface PhotoCardProps {
  image: string;
  description: string;
  index?: number;
  totalInPage?: number;
}

const PhotoCard = ({
  image,
  description,
  index,
  totalInPage,
}: PhotoCardProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      className="max-w-sm flex flex-col min-w-full border rounded-xl relative aspect-square"
      role="figure"
      aria-labelledby={`photo-description-${index}`}
    >
      {!imageLoaded && (
        <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-xl">
          <span className="sr-only">Se încarcă imaginea...</span>
          <div className="animate-pulse h-8 w-8 rounded-full bg-gray-300"></div>
        </div>
      )}
      <img
        className={`w-full object-cover aspect-square rounded-xl ${
          imageLoaded ? 'opacity-100' : 'opacity-0'
        }`}
        src={image}
        alt={description}
        onLoad={() => setImageLoaded(true)}
        loading="lazy"
      />
      <div
        id={`photo-description-${index}`}
        className="text-white text-xl font-bold rounded-xl absolute bottom-0 w-full p-2 bg-black bg-opacity-50"
      >
        {description}
      </div>
    </div>
  );
};

export default PhotoCard;
