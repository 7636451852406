import PhotoCard from './PhotoCard';
import { Photo, ScreenWidth } from '../types';
import { useMemo, useRef, useState } from 'react';
import { useScreenWidth } from '../hooks/tailwindcss';
import DummyPhotoCard from './DummyPhotoCard';

type PhotoGalleryProps = {
  photos: Photo[];
};

const paginatePhotos = (photos: Photo[], photosPerPage: number): Photo[][] => {
  const pages: Photo[][] = [];
  for (let i = 0; i < photos.length; i += photosPerPage) {
    pages.push(photos.slice(i, i + photosPerPage));
  }
  return pages;
};

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const previousPageIndex = useRef(-1);

  const screenWidth = useScreenWidth();

  const photosPerPage = useMemo(() => {
    let _photosPerPage = 3;

    if (screenWidth >= ScreenWidth.md) {
      _photosPerPage = 4;
    }

    if (screenWidth >= ScreenWidth.lg) {
      _photosPerPage = 5;
    }

    if (screenWidth >= ScreenWidth.xl) {
      _photosPerPage = 6;
    }

    return _photosPerPage;
  }, [screenWidth]);

  const paginatedPhotos = useMemo(() => {
    return paginatePhotos(photos, photosPerPage);
  }, [photos, photosPerPage]);

  const currentPage = paginatedPhotos[currentPageIndex];
  const numberOfMissingPhotos = photosPerPage - currentPage.length;
  const totalPages = paginatedPhotos.length;

  const handlePrevPageClick = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
      previousPageIndex.current = currentPageIndex;
    }
  };

  const handleNextPageClick = () => {
    if (currentPageIndex < paginatedPhotos.length - 1) {
      setCurrentPageIndex(currentPageIndex + 1);
      previousPageIndex.current = currentPageIndex;
    }
  };

  return (
    <div
      className="flex w-full items-center gap-2"
      role="region"
      aria-label="Galerie foto"
    >
      <button
        className="z-10 px-3 py-1 border-2 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onClick={handlePrevPageClick}
        disabled={currentPageIndex === 0}
        aria-label="Pagina anterioară"
        aria-disabled={currentPageIndex === 0}
      >
        <span aria-hidden="true">&#10094;</span>
      </button>
      <div
        className={`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 w-full`}
      >
        {currentPage.map((photo, index) => (
          <PhotoCard
            key={photo.src}
            image={photo.src}
            description={photo.description || ''}
            index={index}
            totalInPage={currentPage.length}
          />
        ))}
        {Array.from({ length: numberOfMissingPhotos }, (_, index) => (
          <DummyPhotoCard key={index} aria-hidden={true} />
        ))}
      </div>
      <button
        className="z-10 px-3 py-1 border-2 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onClick={handleNextPageClick}
        disabled={currentPageIndex === paginatedPhotos.length - 1}
        aria-label="Pagina următoare"
        aria-disabled={currentPageIndex === paginatedPhotos.length - 1}
      >
        <span aria-hidden="true">&#10095;</span>
      </button>
      <div className="sr-only" aria-live="polite">
        Pagina {currentPageIndex + 1} din {totalPages}
      </div>
    </div>
  );
};

export default PhotoGallery;
