const HomePage = () => {
  return (
    <main className="m-auto">
      <section
        className="bg-gray-100 w-full px-2 py-12 md:py-24 lg:py-32 flex flex-col items-center justify-center"
        aria-labelledby="welcome-heading"
      >
        <h1
          id="welcome-heading"
          className="text-3xl font-extrabold tracking-tight lg:text-4xl lg:leading-[3.5rem]"
        >
          Bine ai venit pe site-ul Liceul Tehnologic "Danubius"
        </h1>
        <p className="text-xl font-medium text-gray-700">
          Str. Bucuresti, nr. 29, Corabia, Olt
        </p>
        <div className="flex gap-4 mt-4">
          <a
            href="/despre"
            className="bg-black border-black text-white text-md px-6 py-2 shadow rounded-md text-md border-2 font-medium transition duration-300 ease-in-out hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Despre noi
          </a>
          <a
            href="/proiecte"
            className="bg-white text-black text-md px-6 py-2 shadow rounded-md text-md border-2 font-medium transition duration-300 ease-in-out hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Proiecte
          </a>
        </div>
      </section>
      <section
        className="py-4 px-8 rounded-md flex flex-col items-start justify-center row-span-2"
        aria-labelledby="history-heading"
      >
        <h2
          id="history-heading"
          className="text-2xl font-extrabold tracking-tight lg:text-5xl"
        >
          Scurt istoric
        </h2>
        <p className="font-medium text-md py-4">
          În anul 1922 avea să se ridice o școală cu profil de specialitate,
          care, ulterior, avea să devină Liceul Industrial de Băieți. În anul
          1948, după prima reformă a învățământului, Ministerul Agriculturii
          preia baza materială a școlii și dă un nou profil, care avea sa
          devină, la 01.09.1949, Școala Profesională de Mecanică. Datorită
          nevoilor stringente ale noii agriculturi, la 01.09.1953, școala va
          funcționa sub denumirea de Școala Profesională de Tractoriști. Din
          anul 1955, pentru școală începe etapa a doua, funcționând sub numele
          de Școala Profesională de Mecanici Agricoli. Denumirea și meseriile au
          fost schimbate de-a lungul anilor, după cum o cereau interesele locale
          și generale, până când, în anul 1990, școala capată denumirea de
          Grupul Școlar Agricol Corabia. Începând cu 01.09.2008, Grupul Școlar
          Agricol funcționează sub denumirea de Colegiul Tehnic "Danubius"
          Corabia.
          <a
            href="/despre"
            className="text-blue-700 hover:text-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 ml-1"
            aria-label="Citește mai mult despre istoricul liceului"
          >
            Citește mai mult
          </a>
        </p>
      </section>
    </main>
  );
};

export default HomePage;
