import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png';
import ofertaEducationala from '../assets/A0 afis.jpg';

export default function Navbar() {
  const { pathname } = useLocation();

  const navigation = [
    { name: 'Acasa', href: '/', current: false },
    { name: 'Despre', href: '/despre', current: false },
    { name: 'Proiecte', href: '/proiecte', current: false },
    { name: 'Galerie', href: '/galerie', current: false },
    // Separate case for 'Contact'
    { name: 'Contact', href: '/contact', current: false, isContact: true },
  ];

  navigation.forEach(item => {
    item.current = pathname === item.href;
  });

  return (
    <header className="bg-white shadow-sm" role="banner">
      <div className="flex flex-col lg:flex-row items-center justify-between gap-3 w-full z-10 px-6 py-4">
        <div>
          <a
            className="flex items-center gap-1"
            href="/"
            aria-label="Liceul Tehnologic Danubius Corabia - Pagina principală"
          >
            <img
              src={Logo}
              alt="Logo Ministerul Educației"
              className="inline-block w-16 h-16 rounded-sm"
            />
            <div className="text-sm md:text-md text-[#0D0C18] font-medium">
              <p>MINISTERUL EDUCAŢIEI INSPECTORATUL ŞCOLAR AL JUDEŢULUI OLT</p>
              <p className="text-lg md:text-xl lg:text-2xl text-gray-800 font-bold">
                Liceul Tehnologic "Danubius"
                <span className="text-lg text-gray-800 font-normal">
                  {' '}
                  Corabia
                </span>
              </p>
            </div>
          </a>
        </div>

        <div className="lg:hidden w-full border-t shadow-sm"></div>
        <div className="flex flex-col items-center gap-1">
          <nav
            className="flex md:gap-2 self-center items-center justify-center lg:justify-end"
            aria-label="Navigare principală"
          >
            {navigation.map(item =>
              item.isContact ? (
                <a
                  key={item.name}
                  href={item.href}
                  className={`hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-[#0D0C18] text-white text-md px-2 mx-1 py-1 shadow rounded-md font-medium border-1 md:border-2 ${
                    item.current ? 'border-blue-500' : 'border-black'
                  }`}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ) : (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`font-bold hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-[#0D0C18] px-1.5 py-1 rounded-lg text-sm md:text-md ${
                    item.current ? 'text-blue-700 underline' : ''
                  }`}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              )
            )}
          </nav>
          <div className="w-full flex items-center justify-center lg:justify-end space-x-2">
            <a
              href={ofertaEducationala}
              download
              className="text-blue-700 hover:text-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 font-bold"
              aria-label="Descarcă oferta educațională"
            >
              Oferta Educationala
            </a>
            <div className="w-px h-3 bg-gray-300" aria-hidden="true"></div>
            <Link
              to="/Documente"
              className="font-bold hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Documente
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
