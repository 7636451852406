interface DummyPhotoCardProps {
  'aria-hidden'?: boolean;
}

const DummyPhotoCard = (props: DummyPhotoCardProps) => {
  return (
    <div
      className="max-w-sm flex flex-col min-w-full border rounded-xl relative aspect-square bg-gray-100"
      aria-hidden={props['aria-hidden'] || true}
    >
      <div className="w-full object-cover aspect-square rounded-xl" />
      <p className="text-white text-xl font-bold rounded-xl absolute bottom-2 w-full"></p>
    </div>
  );
};

export default DummyPhotoCard;
