import { useEffect } from 'react';
import DownloadFile, { Document } from '../components/DownloadFile';

const documents: Document[] = [
  {
    name: 'Plan Managerial',
    url: '/documente/2025/Plan-managerial-2024-2025.pdf',
    description: 'ANUL ȘCOLAR 2024 - 2025',
  },

  {
    name: 'Plan Operational',
    url: '/documente/2025/Plan-Operational-2024-2025.pdf',
    description: 'ANUL ȘCOLAR 2024 - 2025',
  },

  {
    name: 'Plan dezvoltare SCIM',
    url: '/documente/2025/Program-Dezvoltare-SCIM-2024-2025.pdf',
    description: 'ANUL ȘCOLAR 2024 - 2025',
  },

  {
    name: 'Raport R.A.E.I',
    url: '/documente/2025/Raport-R.A.E.I-2023-2024.pdf',
    description: 'ANUL ȘCOLAR 2023 - 2024',
  },

  {
    name: 'CDL',
    url: '/documente/2025/CDL-2024-2025.pdf',
    description: 'ANUL ȘCOLAR 2024 - 2025',
  },

  {
    name: 'Planul de Actiune',
    url: '/documente/2025/PAS.pdf',
    description: '2020 - 2025',
  },

  {
    name: 'REGULAMENT DE ORGANIZARE ȘI FUNCȚIONARE',
    url: '/documente/2024/3-ROF-2024-2025.pdf',
    description: 'ANUL ȘCOLAR 2024 - 2025',
  },
  {
    name: 'RAPORT PRIVIND STRUCTURA ȘI CALITATEA ÎNVĂȚĂMÂNTULUI',
    url: '/documente/2024/raport-calitate-2023-2024.pdf',
    description: 'ANUL ȘCOLAR 2023-2024',
  },
  {
    name: 'REGULAMENTUL INTERNAL AL Liceului Tehnologic "DANUBIUS" CORABIA',
    url: '/documente/2024/RI-2024-2025.pdf',
    description: 'ANUL ȘCOLAR 2024 - 2025',
  },
];

const declaratiiANI: Document[] = [
  {
    name: 'Virdol - Declarație de Avere',
    url: '/documente/2025/Daclaratii_ANI/Virdol_Da.pdf',
    description: '2025',
  },
  {
    name: 'Virdol - Declarație de Interese',
    url: '/documente/2025/Daclaratii_ANI/Virdol_DI.pdf',
    description: '2025',
  },
  {
    name: 'Roșu - Declarație de Avere',
    url: '/documente/2025/Daclaratii_ANI/Rosu_DA.pdf',
    description: '2025',
  },
  {
    name: 'Roșu - Declarație de Interese',
    url: '/documente/2025/Daclaratii_ANI/Rosu_DI.pdf',
    description: '2025',
  },
  {
    name: 'Dănciulescu - Declarație de Avere',
    url: '/documente/2025/Daclaratii_ANI/Dănciulescu_DA.pdf',
    description: '2025',
  },
  {
    name: 'Dănciulescu - Declarație de Interese',
    url: '/documente/2025/Daclaratii_ANI/Danciulescu_DI.pdf',
    description: '2025',
  },
];

const DocumentePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="flex w-90 m-auto">
      <article className="flex flex-col bg-white py-4 px-8 rounded-md items-center gap-6 w-full">
        <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl lg:leading-[3.5rem] w-full text-left">
          Documente
        </h1>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 w-full">
          {documents.map(document => (
            <DownloadFile key={document.name} document={document} />
          ))}
        </div>

        <h2 className="text-3xl font-bold tracking-tight w-full text-left mt-6">
          Declarații ANI
        </h2>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 w-full">
          {declaratiiANI.map(document => (
            <DownloadFile key={document.name} document={document} />
          ))}
        </div>
      </article>
    </main>
  );
};

export default DocumentePage;
